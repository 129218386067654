import type { SortOrder } from 'antd/lib/table/interface'
import { ESortDir } from 'features/cases-management/types/TCasePagination'
import { SumpSlidesFilters } from 'features/sump/api/query'
import { EUploadedFileSortBy, EUploadedFileTab } from 'types/IUploadedFile'

export const setSortOrder = (filters: SumpSlidesFilters, tabType: EUploadedFileTab, sortBy: EUploadedFileSortBy) =>
  filters?.sortBy === sortBy
    ? filters?.sortDir === 'ASC'
      ? ('ascend' as SortOrder)
      : ('descend' as SortOrder)
    : undefined

export const gerSortDirectionConfig = (sortDir?: ESortDir) =>
  !sortDir ? undefined : sortDir === ESortDir.ASC ? ESortDir.ascend : ESortDir.descend

export const snakeToCamel = (str?: string) =>
  str?.toLowerCase().replace(/(_\w)/g, (m: string) => m.toUpperCase().substr(1)) || ''
