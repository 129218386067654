export type IWorkspace = {
  name: string
  personal: boolean
  slug: string
  userId: number
  workspaceId: string
  settings: ISetting[]
}

export type ISetting = {
  /** Название настройки */
  fieldName: SettingsNameEnum
  /** Значение */
  value: string
  /** Числовое значение */
  valueInt?: number
}

export enum SettingsNameEnum {
  /** Доступность инструмента поиска похожих слайдов в атласе */
  AiTools = 'aiTools',
  /** Доступность функции удаления кейсов */
  CanRemoveCase = 'canRemoveCase',
  /** Доступность аннотирования слайда дефектом */
  enableSlideDefects = 'enableSlideDefects',
  /** Есть ли интеграция с ЛИС */
  LisMode = 'lisMode',
  /** Доступен ли инструмент корегистрации */
  EnableAiSlideCoreg = 'enableAiSlideCoreg',
  /** Доступен ли чат поддержки */
  ShowTelegramChat = 'showTelegramChat',
  /** Доступность функции удаления слайдов */
  CanRemoveImage = 'canRemoveImage',
  /** Доступность страницы отстойника */
  AccessToUnsortedImage = 'accessToUnsortedImage',
  /** Доступность инструмента поиска похожих слайдов */
  EnableAiSearch = 'enableAiSearch',
  /** Период в секундах, в рамках которого можно отменить подписание */
  LisLockSignedMedicalReportAfterSec = 'lisLockSignedMedicalReportAfterSec',
  /** Отображается ли баркод в информации слайда */
  ShowSlideBarcodeInsteadOfCaption = 'showSlideBarcodeInsteadOfCaption',
  /** Доступность AI инструмента Детекция артефактов пробоподготовки */
  EnableAiArtefacts = 'enableAiArtefacts',
  /** Доступность AI инструмента Оценка экспрессии ядерных биомаркеров */
  EnableAiKi67 = 'enableAiKi67',
  /** Доступность AI инструмента Подсчет клеток  */
  EnableAiMitosis = 'enableAiMitosis',
  /** Доступность AI инструмента Сегментация ткани ПЖ */
  EnableAiPvProstate = 'enableAiPvProstate',
  /** Доступность AI инструмента Подсчет суммы баллов по Глисону */
  EnableAiSegmentation = 'enableAiSegmentation',
  /** Доступность AI инструмента Детекция метастазов в ЛУ */
  EnableAiMedicalNeuronets_crc = 'enableAiMedicalNeuronets_crc',
  /** Доступность инструмента "Тип дефекта" */
  EnableDefectTypes = 'defectTypes',
  /** Доступность назначения случаев */
  CaseStageManagement = 'caseStageManagement' /** Доступность назначения случаев */,
  /** Доступность распределения случаев */
  CaseRouting = 'caseRouting',
  /** Доступность информации о "дозаказах" */
  ResearchRequestEnabled = 'researchRequestEnabled',
  /** Окраска по-умолчанию для дозаказов */
  researchRequestDefaultStain = 'researchRequestDefaultStain',
}

/** Типы для настроек ws, описание в енуме SettingsNameEnum */
export type TWsSettingsBoolean = {
  aiTools: boolean
  canRemoveCase: boolean
  enableAiSlideCoreg: boolean
  showTelegramChat: boolean
  canRemoveImage: boolean
  accessToUnsortedImage: boolean
  enableAiSearch: boolean
  showSlideBarcodeInsteadOfCaption: boolean
  enableAiArtefacts: boolean
  defectTypes: boolean
  enableAiKi67: boolean
  enableAiMitosis: boolean
  enableAiPvProstate: boolean
  enableAiSegmentation: boolean
  enableAiMedicalNeuronets_crc: boolean
  caseRouting: boolean
  researchRequestEnabled: boolean
  enableSlideDefects: boolean
}

export type TWsSettingsString = {
  caseStageManagement: string
  lisMode: string
  researchRequestDefaultStain: string
}

export type TWsSettingsInteger = {
  lisLockSignedMedicalReportAfterSec: number
}
