import { Button, Divider, Dropdown, Input, Menu } from 'antd'
import { getDataFromDB } from 'app/indexedDB'
import { useLiveQuery } from 'dexie-react-hooks'
import { useSlideCache } from 'entities/slide'
import { ISlideAdditional } from 'features/additional-order'
import { useAdditionalServiceMutation } from 'features/additional-order/api/query'
import { RESEARCH_REQUEST_TYPE, STAIN_TYPE } from 'features/additional-order/api/type'
import { ErrorMessage } from 'features/additional-order/ui/ModalWrapper/ModalWrapper.styles'
import { useCaseReferencesBlocksQuery, useCaseReferencesQuery } from 'features/cases/api/query'
import { TDict } from 'features/dictionaries'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { t } from 'i18next'
import { useViewerIdSlideState } from 'pages/viewer/lib/common/ViewerPageProvider'
import React, { useEffect, useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'
import { QUERY_TYPE } from 'shared/api'
import { ButtonElement, CheckboxElement, IconElement } from 'shared/ui/kit'
import { ModalProvider } from 'shared/ui/modal'
import styled from 'styled-components'
import ICase from 'types/ICase'

import { BlockCard } from './BlockCard'
import { StainSelector } from './StainSelector'
import { Block, ModalWindowProps, Stain } from './types'

export const StainModal: React.FC<ModalWindowProps> = ({ caseId, onClose, viewerId, visible }) => {
  const queryClient = useQueryClient()

  const caseRecord = queryClient.getQueryData<ICase>([QUERY_TYPE.CASE, caseId])

  const { slideId } = useViewerIdSlideState(viewerId)
  const currentWorkspaceId = useCurrentWorkspaceId()
  const { data: caseReferencesSlides } = useCaseReferencesQuery({
    caseId: caseId,
    workspaceId: currentWorkspaceId,
  })
  const { data: caseReferencesBlocks } = useCaseReferencesBlocksQuery({
    caseId: caseId,
    workspaceId: currentWorkspaceId,
  })
  const [isNewCaseRequested, setIsNewCaseRequested] = useState<boolean>(false)
  const [isNewBlocksEmpty, setIsNewBlocksEmpty] = useState<boolean>(false)
  const [blocks, setBlocks] = useState<Block[]>([])
  const [labComment, setLabComment] = useState<string>('')
  const [selectedBlockId, setSelectedBlockId] = useState<string>('')
  const [requestType, setRequestType] = useState<RESEARCH_REQUEST_TYPE>(
    caseRecord?.fieldSet === 'IHC' ? RESEARCH_REQUEST_TYPE.NEW_STAIN_IHC : RESEARCH_REQUEST_TYPE.NEW_STAIN_HC,
  )
  const { error, isError, isLoading, isSuccess, mutate: addOrder } = useAdditionalServiceMutation(caseId)
  const currentSlide = useSlideCache(Number(slideId))
  const dictionary = useLiveQuery(() => getDataFromDB('STAIN')) as TDict
  const { data: stains = [] } = { ...dictionary }
  const caseSlideReference = caseReferencesSlides?.find(
    (refSlide) => refSlide?.slideExternalId === currentSlide?.barcode,
  )
  const visibleStains = blocks.reduce((acc, block) => {
    const visibleBlockStains = block.stains.filter(
      (stain) =>
        stain.stainType === (RESEARCH_REQUEST_TYPE.NEW_STAIN_HC === requestType ? STAIN_TYPE.HC : STAIN_TYPE.IHC),
    )

    acc = [...acc, ...visibleBlockStains]

    return acc
  }, [] as Stain[])

  const resetModal = () => {
    setIsNewCaseRequested(false)
    setSelectedBlockId('')
    setBlocks([])
    setLabComment('')
    onClose()
  }

  useEffect(() => {
    setRequestType(
      isNewCaseRequested
        ? RESEARCH_REQUEST_TYPE.NEW_STAIN_IHC_NEW_CASE
        : caseRecord?.fieldSet === STAIN_TYPE.IHC
        ? RESEARCH_REQUEST_TYPE.NEW_STAIN_IHC
        : RESEARCH_REQUEST_TYPE.NEW_STAIN_HC,
    )
  }, [caseRecord?.fieldSet, isNewCaseRequested])

  /** После рендерера сразу добавляем блок */
  useEffect(() => {
    ;(!blocks?.length || !selectedBlockId) &&
      caseReferencesBlocks?.length &&
      handleAddBlock(caseReferencesBlocks?.[0].blockExternalId)
  }, [caseReferencesBlocks?.length, selectedBlockId])

  useEffect(() => {
    setIsNewBlocksEmpty(blocks?.length === caseReferencesBlocks?.length)
  }, [blocks?.length])

  useEffect(() => {
    isSuccess && resetModal()
  }, [isSuccess])

  const blockItemMenu = useMemo(
    () => (
      <Menu>
        {caseReferencesBlocks?.flatMap((block) => {
          const isExists = blocks.find((b) => b.id === block.blockExternalId)

          return !isExists ? (
            <Menu.Item key={block.blockExternalId}>
              <Button
                style={{ textAlign: 'left', width: '100%' }}
                type="text"
                onClick={() => handleAddBlock(block.blockExternalId)}
              >{`${t('Блок')} ${block.blockExternalId}`}</Button>
            </Menu.Item>
          ) : (
            []
          )
        })}
      </Menu>
    ),
    [caseReferencesBlocks, blocks],
  )

  const handleAddBlock = (blockId: string) => {
    const newBlock: Block = {
      id: blockId,
      name: `${t('Блок')} ${blockId}`,
      stains: [],
    }
    setSelectedBlockId(blockId)
    setBlocks([...blocks, newBlock])
  }

  const handleClickBlock = (blockId: string) => {
    setSelectedBlockId(blockId)
  }

  const handleRemoveBlock = (blockId: string) => {
    setBlocks(blocks.filter((block) => block.id !== blockId))
  }

  const handleStainSelect = (stains: Stain[]) => {
    const newBlocks = [...blocks]
    const currentBlock = newBlocks.find((block) => block.id === selectedBlockId)

    if (currentBlock) {
      currentBlock.stains = stains
      setBlocks(newBlocks)
    }
  }

  /** Обработчик сохранения окрасок */
  const handleSubmit = async () => {
    const payload = {
      newCaseRequested: isNewCaseRequested,
      preparationComment: labComment || undefined,
      slides: blocks?.reduce((acc, block) => {
        block?.stains.forEach((stain) => {
          if (
            stain.stainType === (requestType === RESEARCH_REQUEST_TYPE.NEW_STAIN_HC ? STAIN_TYPE.HC : STAIN_TYPE.IHC)
          ) {
            acc.push({
              caseBlockReferenceId: caseReferencesBlocks?.find((refBlock) => refBlock?.blockExternalId === block?.id)
                ?.caseBlockReferenceId,
              caseSlideReferenceId: caseReferencesSlides?.find((refSlide) => refSlide?.blockExternalId === block?.id)
                ?.caseSlideReferenceId,
              stain: stains.find((s) => String(s.id) === String(stain.id)),
            })
          }
        })

        return acc
      }, [] as ISlideAdditional[]),
      type: requestType,
    }

    addOrder(payload, { onSuccess: () => onClose() })
  }

  return (
    <StyledModal
      title={t('Новая окраска')}
      visible={visible}
      closeIcon={<IconElement name="cross16" size="md" onClick={onClose} />}
      footer={[
        <ButtonElement key="cancel" onClick={resetModal}>
          {t('Отменить')}
        </ButtonElement>,
        <ButtonElement
          key="submit"
          type="primary"
          disabled={!visibleStains.length}
          onClick={handleSubmit}
          loading={isLoading}
        >
          {t('Отправить')}
        </ButtonElement>,
      ]}
    >
      <StyledCheckboxElement checked={isNewCaseRequested} onClick={() => setIsNewCaseRequested(!isNewCaseRequested)}>
        {t('Создать новый случай для дополнительных окрасок')}
      </StyledCheckboxElement>
      <Container>
        <BlockContainer>
          {blocks.map((block) => (
            <>
              <BlockCard
                key={block.id}
                block={block}
                requestType={requestType}
                isSelected={block.id == selectedBlockId}
                onRemove={handleRemoveBlock}
                onClick={handleClickBlock}
              />
              <Divider />
            </>
          ))}
          <Dropdown disabled={isNewBlocksEmpty || isLoading} overlay={blockItemMenu} placement="bottomLeft">
            <ButtonElement disabled={isNewBlocksEmpty || isLoading}>{t('Добавить блок')}</ButtonElement>
          </Dropdown>
        </BlockContainer>
        <StainContainer>
          <StainSelector
            requestType={requestType}
            disabled={isLoading || !blocks.length}
            selectedStains={blocks.find((block) => block.id === selectedBlockId)?.stains || []}
            onStainSelect={handleStainSelect}
          />
        </StainContainer>
      </Container>
      <Label>{t('Комментарий для лаборанта')}</Label>
      <Input.TextArea value={labComment} rows={3} onChange={(e) => setLabComment(e.target.value)} />
      <Divider />
      {isError && <ErrorMessage>{error?.message}</ErrorMessage>}
    </StyledModal>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex-direction: row;
  max-height: 300px;
  border-bottom: 1px solid var(--color-border-1);
  margin-bottom: 8px;
`

const BlockContainer = styled.div`
  overflow-y: scroll;
  padding: 8px 8px 8px 0;
  width: 252px;
  max-height: inherit;
  button {
    width: 100%;
  }
`

const StainContainer = styled.div`
  padding-left: 8px;
  width: 252px;
`

export const StyledModal = styled(ModalProvider)`
  .ant-modal-content {
    width: 536px;
  }
  .ant-modal-content .ant-modal-body {
    padding-left: 16px;
    padding-right: 16px;
  }
  .ant-modal-header {
    padding: 16px 16px 5px !important;
  }
`
const StyledCheckboxElement = styled(CheckboxElement)`
  border-bottom: 1px solid var(--color-border-1);
  border-top: 1px solid var(--color-border-1);

  padding: 8px 0px;
  align-items: center;
  gap: 4px;
  width: 100%;
  line-height: 18px;
`
const Label = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: var(--color-text-3);
  margin-bottom: 4px;
`
