import { useTypedSelector } from 'app/redux/lib/selector'
import { ECaseTableType } from 'features/cases-management/types/ECaseTableType'
import { TQueryParams } from 'features/cases-management/types/TCasePagination'
import { useDefectsTableQuery } from 'features/defects/api/query'
import { EDefectsTableType } from 'features/defects/types/TDefectsPagination'
import { transformFilters } from 'features/thumbnails/common/utils'
import { selectDefectsTabUrl } from 'pages/viewer/model/viewerPageSlice'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { TABS_QUERY_PARAMS_KEY } from 'shared/lib/common/storageKeys'
import { getFromLocalStorage } from 'shared/lib/local-storage'
import { TCasesManagementTabs } from 'types/TTab'

const DEFAULT_PAGE_SIZE = 20

export const useSlidesDefects = () => {
  const tabsQueryParams: TQueryParams = getFromLocalStorage(TABS_QUERY_PARAMS_KEY) || {}
  const defectsTabUrl: TCasesManagementTabs = useSelector(selectDefectsTabUrl) || EDefectsTableType.UNVIEWED
  const { defectsFilters } = useTypedSelector((state) => state.viewerPage)
  const filters = transformFilters(defectsFilters)
  const [cursor, setCursor] = useState<string | undefined>()

  const {
    defectsList,
    fetchNextPage,
    hasNextPage: queryHasNextPage,
    isLoading: isLoadingDefects,
  } = useDefectsTableQuery(
    defectsTabUrl as EDefectsTableType,
    DEFAULT_PAGE_SIZE,
    tabsQueryParams[ECaseTableType.DEFECTS]?.[defectsTabUrl]?.queryParams,
    cursor,
    filters,
  )

  const slides = defectsList.map((defect) => ({
    ...defect.slide,
    caseId: defect.caseId,
  }))
  const slidesIds = defectsList.map((defect) => defect.slide.slideId)

  // Функция для загрузки следующей страницы
  const fetchMoreSlides = useCallback(async () => {
    if (queryHasNextPage) {
      await fetchNextPage() // Загружаем следующую страницу
      setCursor(String(defectsList[defectsList.length - 1]?.slide.slideId)) // Обновляем курсор на последний элемент
    }
  }, [queryHasNextPage, defectsList, fetchNextPage])

  return {
    defectsList,
    fetchMoreSlides,
    isLastPage: !queryHasNextPage,
    isLoadingDefects,
    slides,
    slidesIds,
  }
}
