import { useCasesCountQuery } from 'features/cases-management/api/query'
import {
  EAllCasesTabType,
  ECaseTableType,
  EDistributionTabType,
  EMyCasesTabType,
} from 'features/cases-management/types/ECaseTableType'
import { useCaseManagementContext } from 'features/cases-management/ui/CaseManagementContext'
import CaseManagementTabContext from 'features/cases-management/ui/CaseManagementTabContext'
import { CasesTableTabs } from 'features/cases-management/ui/cases-table/CasesTableTabs'
import { EDefectsTableType } from 'features/defects/types/TDefectsPagination'
import { useCaseManagementRouteParam } from 'pages/cases-management/CasesManagementRoutes'
import { useTranslation } from 'react-i18next'
import { useSettingsAndUserRoles } from 'shared/lib/workspaces'
import { IconElement } from 'shared/ui/kit'
import styled from 'styled-components'

type TTabInfo = {
  /** Название таба */
  label: string | JSX.Element
  tabType: EAllCasesTabType | EMyCasesTabType | EDistributionTabType | EDefectsTableType
  color?: string
  /** Видимость таба, дефолт = true */
  visible?: boolean
}

export const Span = styled.span`
  font-size: 14px;
  color: var(--color-text-primary);
`

export const TabContainer = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
`

const CasesTabsContainer = () => {
  const { t } = useTranslation()
  const { currentTab, menuTab } = useCaseManagementRouteParam()
  const { selectedSlidesWithDefects, setSelectedDefectRowKeys, setSelectedSlidesWithDefects } =
    useCaseManagementContext()
  const { data } = useCasesCountQuery(menuTab)
  const {
    isCaseRouting,
    isDefectsVisible,
    isLisMode,
    isRemoveVisible,
    isResearchRequestEnabled,
    isRoutingVisible,
    isWorklistVisible,
    isWorkspaceVisible,
    roles: { isRoleUser },
  } = useSettingsAndUserRoles()

  const tabs: Record<ECaseTableType, { visible?: boolean; tabs: TTabInfo[] }> = {
    [ECaseTableType.ROUTING]: {
      tabs: [
        { label: t('Все без заключения'), tabType: EDistributionTabType.NOT_COMPLETED },
        { color: 'var(--color-green)', label: t('Распределено'), tabType: EDistributionTabType.ASSIGNED },
        {
          color: 'var(--color-blue)',
          label: t('Не распределено'),
          tabType: EDistributionTabType.NOT_ASSIGNED,
        },
        {
          color: 'var(--color-red)',
          label: t('Перераспределение'),
          tabType: EDistributionTabType.DECLINED,
        },
      ],
      visible: isRoutingVisible,
    },
    [ECaseTableType.WORKLIST]: {
      tabs: [
        {
          color: 'var(--color-text-1)',
          label: t('Рабочий список'),
          tabType: EMyCasesTabType.ASSIGNED_TO_ME,
          visible: isRoleUser,
        },
        {
          color: 'var(--color-text-1)',
          label: t('Входящие консультации'),
          tabType: EMyCasesTabType.INCOMING,
        },
        {
          color: 'var(--color-text-1)',
          label: t('Отслеживаемые случаи'),
          tabType: EMyCasesTabType.FAVORITES,
          visible: isRoleUser && isCaseRouting,
        },
        {
          color: 'var(--color-text-1)',
          label: t('Дозаказы'),
          tabType: EMyCasesTabType.ASSIGNED_TO_ME_WITH_RESEARCH_REQUESTS,
          visible: isRoleUser && isResearchRequestEnabled,
        },
      ],
      visible: isWorklistVisible,
    },
    [ECaseTableType.WORKSPACE]: {
      tabs: [
        { label: t('Все статусы'), tabType: EAllCasesTabType.ALL_STATUSES },
        {
          color: 'var(--color-text-1)',
          label: t('Открытые'),
          tabType: EAllCasesTabType.OPENED,
        },
        {
          color: 'var(--color-text-1)',
          label: isLisMode ? t('Подписанные') : t('Завершённые'),
          tabType: EAllCasesTabType.COMPLETED,
        },
        {
          color: 'var(--color-text-1)',
          label: t('Удалённые случаи'),
          tabType: EAllCasesTabType.DELETED,
          visible: isRemoveVisible,
        },
      ],
      visible: isWorkspaceVisible,
    },
    [ECaseTableType.DEFECTS]: {
      tabs: [
        ...(selectedSlidesWithDefects.length
          ? [
              {
                color: 'var(--color-text-1)',
                label: (
                  <TabContainer>
                    <IconElement
                      className="cross-icon-bubble-button"
                      size="md"
                      defaultFill={false}
                      name="cross16"
                      hoverColor="var(--color-text-1)"
                      onClick={() => {
                        setSelectedDefectRowKeys([])
                        setSelectedSlidesWithDefects([])
                      }}
                    />
                    <Span>{t('Выбрано')}</Span>
                    <Span>{selectedSlidesWithDefects.length}</Span>
                  </TabContainer>
                ),
                tabType: currentTab,
              },
            ]
          : [
              {
                label: t('Новые'),
                tabType: EDefectsTableType.UNVIEWED,
              },
              {
                color: 'var(--color-text-1)',
                label: t('Просмотренные'),
                tabType: EDefectsTableType.VIEWED,
              },
              {
                color: 'var(--color-text-1)',
                label: t('Все дефекты'),
                tabType: EDefectsTableType.ALL,
              },
            ]),
      ],
      visible: isDefectsVisible,
    },
  }

  const visibleMenuTabs = Object.fromEntries(
    Object.entries(tabs).filter(([_, value]) => value.visible === undefined || value.visible),
  )

  const tabInfo = visibleMenuTabs[menuTab]?.tabs || [
    {
      color: 'var(--color-text-1)',
      label: t('Входящие консультации'),
      tabType: EMyCasesTabType.INCOMING,
    },
  ]

  const tabsItems = tabInfo
    .filter(({ visible }) => visible === undefined || visible)
    .map(({ color, label, tabType }, index) => ({
      color: color || 'var(--color-text-1)',
      count:
        !selectedSlidesWithDefects.length && data && menuTab === ECaseTableType.DEFECTS
          ? Number(data[tabType])
          : data?.[tabType]
          ? data[tabType].total
          : 0,
      id: (index + 1).toString(),
      label: typeof label === 'string' ? t(label) : label,
      specialCount:
        !isCaseRouting && tabType === EMyCasesTabType.ASSIGNED_TO_ME
          ? data?.[EAllCasesTabType.OPENED]?.total || 0
          : undefined,
      tabType,
    }))

  return (
    <CaseManagementTabContext>
      <CasesTableTabs tabsItems={tabsItems} />
    </CaseManagementTabContext>
  )
}

export default CasesTabsContainer
